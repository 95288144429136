import React, { useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import Header from "../../commons/header/header";
import Footer from "../../commons/footer/footer";
import "./nirf.css";
import image3 from "../../../assests/images/nirf.jpg";


const DisciplineCards = () => {
  const [showDisciplines, setShowDisciplines] = useState(false);


  const disciplines = [
    { name: "Engineering", url: "/pdf/Engineering.pdf" },
    { name: "Overall", url: "/pdf/2024-2025-Overall.pdf" },
  ];

  const handleDisciplineClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <Header />
      <div className="image-section">
        <img src={image3} alt="Gallery" className="department-image" />
        {/* <div className="overlay-text">NIRF</div> */}
      </div>
      <Container className="mt-4 px-4" id="nirf-container">
        {!showDisciplines ? (
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={4}>
              <Card onClick={() => setShowDisciplines(true)} className="clickable-card">
                <Card.Body>
                  <Card.Title>2024 - 2025</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-center">
            {disciplines.map((discipline, index) => (
              <Col xs={12} sm={6} md={4} key={index}>
                <Card onClick={() => handleDisciplineClick(discipline.url)} className="clickable-card">
                  <Card.Body>
                    <Card.Title>{discipline.name}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default DisciplineCards;